<template>
  <section id="wine">
    <!-- <nav>
      <div class="title">
        <router-link to="/rufina">  Wine<v-icon right>{{ svgBottle }}</v-icon></router-link>
        <router-link to="/prata">  Vineyards
                      <v-icon right>{{ svgGrape }}</v-icon></router-link>
        <router-link exact to="/"> Tastings
                      <v-icon right>{{ svgWine }}</v-icon></router-link>
                        <router-link exact to="/">
                     Winery &nbsp;
                      <font-awesome-icon right :icon="['fas', 'warehouse']" /></router-link>
      </div>
    </nav> -->
    <hero />

    <contents />
  </section>
</template>

<script>
  import {
    mdiGlassWine,
    mdiCompass,
    mdiFruitGrapes,
    mdiFruitGrapesOutline,
    mdiBottleWine
  } from '@mdi/js'

  export default {
    name: 'WineIndex',

    components: {
      Hero: () => import('./Hero'),
      Contents: () => import('./Contents')
    },

    data: () => ({
      svgWine: mdiGlassWine,
      svgCompass: mdiCompass,
      svgGrape: mdiFruitGrapes,
      svgGrapeOut: mdiFruitGrapesOutline,
      svgBottle: mdiBottleWine,

      links: [
        {
          to: '/',
          text: 'Home'
        },
        {
          to: '/story',
          text: 'Story'
        },
        {
          to: '/experience',
          text: 'Experience'
        },
        {
          to: '/wine',
          text: 'Wine'
        },
        {
          to: '/news',
          text: 'News'
        },
        {
          to: '/contact',
          text: 'Contact'
        }
      ]
    }),

    metaInfo: {
      title: 'Wine'
    }
  }
</script>

<style scoped>
nav {
  width: 50px;
  background: #27aae1; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #27aae1 0%,
    #ec008c 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #27aae1 0%,
    #ec008c 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #27aae1 0%,
    #ec008c 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#27aae1', endColorstr='#ec008c',GradientType=0 );
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 3000;
}

.title {
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  font-size: 20px;
  color: white;
  z-index: 6000;
  margin-left: 17px;
  writing-mode: vertical-rl;
}

.title a,
.title a:hover {
  color: rgba(0, 0, 0, 0.8);
  margin: 60px 0px;
}

.title a.router-link-active {
  color: black;
  transition: 0.2s color ease;
}
</style>
