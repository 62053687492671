<template>
  <section
    id="experience"
    :height="$vuetify.breakpoint.xsOnly ? '100vh' : '150vh'"
    class="small"
  >
    <hero />
  </section>
</template>

<script>
  export default {
    name: 'ExperienceIndex',

    metaInfo: {
      title: 'Experience'
    },

    components: {
      Hero: () => import('./Hero')
    }
  }
</script>

<style scoped>
.v-card {
  border-radius: 0px;
}
.small {
  padding-top: 0px;
  padding-bottom: 40px;
}
</style>
