<template>
  <section id="story">
    <section id="hero">
      <v-sheet
        dark
        tile
      >
        <v-img
          :height="$vuetify.breakpoint.xsOnly ? '100vh' : '100vh'"
          :src="require('@/assets/Main/history/history (4).webp')"
          :gradient="gradient"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              />
            </v-row>
          </template>

          <v-container
            light
            fill-height
            fluid
            :px-12="$vuetify.breakpoint.mdAndUp"
          >
            <v-row
              align="center"
              justify="start"
            >
              <v-row>
                <v-slide-x-transition appear>
                  <v-col
                    cols="12"
                    md="7"
                  >
                    <div>
                      <h1
                        :class="[
                          $vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline'
                        ]"
                        class="font-weight-bold"
                      >
                        Indigenous Plants
                      </h1>

                      <v-divider class="mb-3" />

                      <div class="subtitle mb-0">
                        <p>
                          <em>We have compiled a list of plants that we offer for
                            the most common situations. There are plenty of
                            other suitable plants available at the nursery
                            including ground covers and flowering shrubs.We
                            encourage all visitors to plant indigenous plants in
                            their gardens as they are better suited to our South
                            African climate and add value to the habitat.</em>
                        </p>
                      </div>
                    </div>
                  </v-col>
                </v-slide-x-transition>
              </v-row>
              <v-row>
                <v-btn
                  color="thirdley"
                  class="ml-3"
                  tile
                  outlined
                  @click="ShowMenuClicked = true"
                >
                  Plant List &nbsp;&nbsp;
                  <span style="padding-left: 3px; font-size: 16px;">
                    <font-awesome-icon
                      right
                      :icon="['fas', 'book-open']"
                    />
                  </span>
                </v-btn>
                <v-btn
                  href="#details"
                  color="thirdley"
                  class="ml-3"
                  tile
                  outlined
                >
                  Details&nbsp;
                  <v-icon>{{ svgArrow }}</v-icon>
                </v-btn>
              </v-row>
              <v-overlay
                absolute
                :value="ShowMenuClicked"
              >
                <v-col cols="10">
                  <iframe
                    v-if="ShowMenuClicked"
                    src="/static/plant-list.pdf"
                    :height="[$vuetify.breakpoint.smAndUp ? '700' : '400']"
                    :width="[$vuetify.breakpoint.smAndUp ? '900' : '250']"
                  />
                </v-col>
                <v-col cols="2">
                  <v-btn
                    icon
                    @click="ShowMenuClicked = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-overlay>
            </v-row>
          </v-container>
        </v-img>
      </v-sheet>
    </section>

    <section
      id="content"
      style="margin-bottom: 80px"
    >
      <v-card
        class="mx-auto pb-2 mb-9"
        elevation="24"
        light
        max-width="97%"
        style="margin-top: -64px"
        v-bind="$attrs"
        tile
        outlined
      >
        <v-sheet
          max-width="1000"
          class="mx-auto mb-6"
          tile
        >
          <v-container>
            <plants />
          </v-container>
        </v-sheet>
      </v-card>
    </section>
  </section>
</template>

<script>
// import Experiences from '../../components/Experiences.vue'
  export default {
    name: 'StoryIndex',
    components: {
      // Experiences: () => import('@/components/Experiences.vue'),
      Plants: () => import('@/components/Plants.vue')
    // Projects: () => import('@/components/Projects.vue')
    },

    props: {
      gradient: {
        type: String,
        default: 'to top, rgba(0,0,0,.4), rgba(0,0,0,.4)'
      }
    },

    data: () => ({
      ShowMenuClicked: false
    }),

    metaInfo: {
      title: 'Story'
    }
  }
</script>
